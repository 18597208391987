.card_underline {
    display: flex;
    flex-direction: column;
    width: 35vw;
    min-width: 290px;
    max-width: 400px;
    height: fit-content;
    margin: var(--margin);
    margin-top: calc(2*var(--margin));
    border-radius: 15px;
    box-shadow: var(--box-shadow);
    background-color: var(--color_background);
    
}

.card_underline .underline_title {
    display: flex;
    margin-bottom: 20px;
}

.card_underline .underline_title h1 {
    position: relative;
    margin-bottom: 0;
    margin-left: var(--margin);
    font-size: var(--font-size_l);
}

.card_underline .underline_title h1::after {
    content: "";
    position: absolute;
    height: 10px;
    width: calc(100%);
    left: -10px;
    bottom: -10px;
    background-color: var(--color_forth);
}

.card_underline .description_holder {
    display: flex;
    flex-direction: row;
    margin: var(--margin);
}

.card_underline .description_holder .description{
    align-self: start;
    font-size: var(--font-size_s);
    margin-right: var(--margin);
    width: 50%;
}